<template>
  <v-container
    grid-list-xl
  >
    <v-form>
      <v-layout
        v-if="hasAction('view_settings') || isAdmin()"
        layout
        wrap>
        <!-- <v-flex
          xs12
          sm6
          md6
        >
          <v-textarea
            v-model="baoKimDomain"
            :label="$t('generalConfiguration.domain')"
            rows="4"
          />
        </v-flex> -->
        <v-flex
          xs12
          sm6
          md6
        >
          <v-textarea
            v-model="baoKimApiKey"
            :label="$t('baoKimConfig.baoKimApiKey')"
            rows="4"
          />
        </v-flex>
        <v-flex
          xs12
          sm6
          md6
        >
          <v-textarea
            v-model="baoKimSecretKey"
            :label="$t('baoKimConfig.baoKimSecretKey')"
            rows="4"
          />
        </v-flex>
        <v-flex
          xs12
          sm6
          md6
        >
          <v-textarea
            v-model="baoKimMerchantId"
            :label="$t('baoKimConfig.baoKimMerchantId')"
            rows="4"
          />
        </v-flex>
        <!-- <v-flex
          xs12
          sm4
          md4>
          <span style="overflow-wrap: break-word;">
            {{ $t('lienVietConfig.WAPUrl') }}
            <v-tooltip
              top>
              <template v-slot:activator="{ on }">
                <v-icon
                  class="ml-2"
                  v-on="on"
                  @click="onCopyText(getWAPUrl())">mdi-content-copy</v-icon>
              </template>
              <span v-if="!urlCopied">{{ $t('entity.copyUrl') }}</span>
              <span
                v-else
                style="overflow-wrap: break-word;">{{ $t('entity.copied') }}: {{ urlCopied }}</span>
            </v-tooltip>
          </span>
        </v-flex>
        <v-flex
          xs12
          sm4
          md4>
          <span style="overflow-wrap: break-word;">
            {{ $t('lienVietConfig.agentAccess') }}: <span class="font-weight-bold">{{ agentAccess }} <v-tooltip
              top>
              <template v-slot:activator="{ on }">
                <v-icon
                  class="ml-2"
                  v-on="on"
                  @click="onCopyText(agentAccess)">mdi-content-copy</v-icon>
              </template>
              <span v-if="!urlCopied">{{ $t('entity.copyUrl') }}</span>
              <span
                v-else
                style="overflow-wrap: break-word;">{{ $t('entity.copied') }}: {{ urlCopied }}</span>
            </v-tooltip></span>
          </span>
        </v-flex>
        <v-flex
          xs12
          sm4
          md4>
          <span style="overflow-wrap: break-word;">
            {{ $t('lienVietConfig.agentSite') }}: <span class="font-weight-bold">{{ agentSite }} <v-tooltip
              top>
              <template v-slot:activator="{ on }">
                <v-icon
                  class="ml-2"
                  v-on="on"
                  @click="onCopyText(agentSite)">mdi-content-copy</v-icon>
              </template>
              <span v-if="!urlCopied">{{ $t('entity.copyUrl') }}</span>
              <span
                v-else
                style="overflow-wrap: break-word;">{{ $t('entity.copied') }}: {{ urlCopied }}</span>
            </v-tooltip></span>
          </span>
        </v-flex> -->
      </v-layout>
      <v-btn
        v-if="hasAction('update_settings') || isAdmin()"
        style="margin-right: 0;"
        class="pull-right"
        color="success"
        @click="onCreateLbpConfig"
      >
        <span class="btn-label mr-3">
          <i class="glyphicon glyphicon-ok" />
        </span>
        <span style="text-transform: none;">{{ $t("common.save") }} </span>
      </v-btn>
    </v-form>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import stringUtils from 'utils/stringUtils'
import ToastType from 'enum/toastType'
import functionUtils from 'utils/functionUtils'
export default {
  components: {
  },
  data: () => ({
    objectSettingKey: stringUtils.ObjectSettingKey,
    baoKimApiKey: null,
    baoKimSecretKey: null,
    baoKimMerchantId: null,
    baoKimDomain: null,
    urlCopied: null,
    agentSite: null,
    agentAccess: null,
    accessCode: null,
    merchantSite: null
  }),
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR',
      'GET_CURRENT_ACCOUNT'
    ])
  },
  created () {
    this.getBaoKimApiKey()
    this.getBaoKimSecretKey()
    this.getBaoKimMerchantId()
  },
  methods: {
    /**
     * Get url
     */
    getWAPUrl: function () {
      return functionUtils.removeDoubleSlashesFromUrl(stringUtils.DOMAIN_BASE_PATH_ADMIN + '/wap-content')
    },
    /**
     * Copy text
     */
    onCopyText: function (text) {
      navigator.clipboard.writeText(text)
      this.urlCopied = text
    },
    getBaoKimSecretKey: function () {
      let filter = {
        params: {
          getWithKey: true,
          key: this.objectSettingKey.BAO_KIM_API_SECRET
        }
      }
      this.GET_OBJECT_SETTING(filter).then(
        function (res) {
          let data = res.data.data
          if (data.length !== 0) {
            this.baoKimSecretKey = data[0].value
          }
        }.bind(this)
      )
    },
    /**
     * Get Bao Kim public key
     */
    getBaoKimApiKey: function () {
      let filter = {
        params: {
          getWithKey: true,
          key: this.objectSettingKey.BAO_KIM_API_KEY
        }
      }
      this.GET_OBJECT_SETTING(filter).then(
        function (res) {
          let data = res.data.data
          if (data.length !== 0) {
            this.baoKimApiKey = data[0].value
          }
        }.bind(this)
      )
    },
    getBaoKimMerchantId: function () {
      let filter = {
        params: {
          getWithKey: true,
          key: this.objectSettingKey.BAO_KIM_MERCHANT_ID
        }
      }
      this.GET_OBJECT_SETTING(filter).then(
        function (res) {
          let data = res.data.data
          if (data.length !== 0) {
            this.baoKimMerchantId = data[0].value
          }
        }.bind(this)
      )
    },
    // Check is admin
    isAdmin: function () {
      return functionUtils.isAdmin(this.GET_CURRENT_ACCOUNT)
    },
    /**
     * Has action
     */
    hasAction: function (metaCode) {
      return functionUtils.hasAction(this.GET_CURRENT_ACCOUNT, metaCode)
    },
    /**
     * create setting config
     */
    createSettingConfig: function (key, value) {
      let filter = {
        objectId: null,
        objectType: 'System',
        key: key,
        value: value
      }
      this.CREATE_OBJECT_SETTING(filter)
    },

    /**
     * Create or update hotline config
     */
    onCreateLbpConfig: function (event) {
      event.preventDefault()
      this.createSettingConfig(this.objectSettingKey.BAO_KIM_API_KEY, this.baoKimApiKey)
      this.createSettingConfig(this.objectSettingKey.BAO_KIM_API_SECRET, this.baoKimSecretKey)
      this.createSettingConfig(this.objectSettingKey.BAO_KIM_MERCHANT_ID, this.baoKimMerchantId)
      this.ON_SHOW_TOAST({
        'message': this.$t('common.success'),
        'styleType': ToastType.SUCCESS
      })
    },
    ...mapActions([
      'CREATE_OBJECT_SETTING',
      'GET_OBJECT_SETTING',
      'ON_SHOW_TOAST'
    ])
  }
}
</script>

<style>

</style>

<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <!-- Config MOMO payment -->
    <v-layout v-if="isAdmin()">
      <v-flex md12>
        <material-card
          :title="$t('payment.title.momo')"
          color="green"
          flat
          full-width
        >
          <payment-momo-config />
        </material-card>
      </v-flex>
    </v-layout>
    <!-- Config Lien Viet -->
    <v-layout v-if="isAdmin()">
      <v-flex md12>
        <material-card
          :title="$t('lienVietConfig.title')"
          color="green"
          flat
          full-width
        >
          <lienVietConfig />
        </material-card>
      </v-flex>
    </v-layout>
    <!-- Config Haravan -->
    <v-layout v-if="hasEntityRole('hasGiveVoucherToHaravan')">
      <v-flex md12>
        <material-card
          :title="'Access token haravan'"
          color="green"
          flat
          full-width
        >
          <haravanTokenConfig />
        </material-card>
      </v-flex>
    </v-layout>
    <!-- Config Bao Kim -->
    <v-layout v-if="isAdmin()">
      <v-flex md12>
        <material-card
          :title="$t('baoKimConfig.title')"
          color="green"
          flat
          full-width
        >
          <baoKimConfig />
        </material-card>
      </v-flex>
    </v-layout>
    <v-layout v-if="isAdmin()">
      <v-flex md12>
        <material-card
          :title="$t('HDBankKeySetting.title')"
          color="green"
          flat
          full-width
        >
          <hDBankKeySetting />
        </material-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { FOR_CONASI_TARGET } from 'utils/constants'
import functionUtils from 'utils/functionUtils'
import HaravanTokenConfig from './HaravanTokenConfig.vue'
import LienVietConfig from './LienVietConfig.vue'
import PaymentMomoConfig from './PaymentMomoConfig.vue'
import BaoKimConfig from './BaoKimConfig.vue'
import HDBankKeySetting from './HDBankKeySetting.vue'
export default {
  components: {
    HaravanTokenConfig,
    LienVietConfig,
    PaymentMomoConfig,
    BaoKimConfig,
    HDBankKeySetting
  },
  data: () => ({
    forConasi: FOR_CONASI_TARGET
  }),
  computed: {
    ...mapGetters([
      'GET_CURRENT_ACCOUNT'
    ])
  },
  methods: {
    hasEntityRole: function (role) {
      return functionUtils.hasEntityRoles(role)
    },
    isAdmin: function () {
      return functionUtils.isAdmin(this.GET_CURRENT_ACCOUNT)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

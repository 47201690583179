<template>
  <v-container
    grid-list-xl
  >
    <v-form>
      <v-layout
        v-if="hasAction('view_settings') || isAdmin()"
        layout
        wrap>
        <v-flex
          xs12
          sm12
          md12
        >
          <v-radio-group
            v-model="enablePayment"
            row
          >
            <v-radio
              :value="true"
              :label="$t('payment.enableMethod')"
            />
            <v-radio
              :value="false"
              :label="$t('payment.disableMethod')"
            />
          </v-radio-group>
        </v-flex>
        <v-flex
          xs12
          sm6
          md6
        >
          <p>{{ $t('payment.momo.guideline.introduction') }}</p>
          <p>{{ $t('payment.momo.guideline.step_0') }}</p>
          <ol>
            <li v-html="$t('payment.momo.guideline.step_1')" />
            <li v-html="$t('payment.momo.guideline.step_2')" />
            <li v-html="$t('payment.momo.guideline.step_3')" />
          </ol>
        </v-flex>
        <v-flex
          xs12
          sm6
          md6
        >
          <span>{{ $t('payment.momo.infoHint') }}</span>
          <v-flex
            xs12
            sm12
            md12
          >
            <v-text-field
              v-model="partnerCode"
              :label="$t('payment.momo.partnerCode')"
              style="margin-right: 5px;"
              class="purple-input"
            />
          </v-flex>
          <v-flex
            xs12
            sm12
            md12
          >
            <v-text-field
              v-model="accessCode"
              :label="$t('payment.momo.accessCode')"
              style="margin-right: 5px;"
              type="password"
              class="purple-input"
            />
          </v-flex>
          <v-flex
            xs12
            sm12
            md12
          >
            <v-text-field
              v-model="secretKey"
              :label="$t('payment.momo.secretKey')"
              style="margin-right: 5px;"
              class="purple-input"
            />
          </v-flex>
        </v-flex>
      </v-layout>
      <v-btn
        v-if="hasAction('update_settings') || isAdmin()"
        style="margin-right: 0;"
        class="pull-right"
        color="success"
        @click="onCreatePaymentMomoConfig"
      >
        <span class="btn-label mr-3">
          <i class="glyphicon glyphicon-ok" />
        </span>
        <span style="text-transform: none;">{{ $t("common.save") }} </span>
      </v-btn>
    </v-form>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import stringUtils from 'utils/stringUtils'
import ToastType from 'enum/toastType'
import functionUtils from 'utils/functionUtils'
export default {
  components: {
  },
  data: () => ({
    MOMO: {
      ENABLE_PAYMENT: stringUtils.ObjectSettingKey.PAYMENT_MOMO_ENABLE_PAYMENT,
      PARTNER_CODE: stringUtils.ObjectSettingKey.PAYMENT_MOMO_PARTNER_CODE,
      ACCESS_CODE: stringUtils.ObjectSettingKey.PAYMENT_MOMO_ACCESS_CODE,
      SECRET_KEY: stringUtils.ObjectSettingKey.PAYMENT_MOMO_SECRET_KEY
    },
    enablePayment: true,
    partnerCode: null,
    accessCode: null,
    secretKey: null
  }),
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR',
      'GET_CURRENT_ACCOUNT'
    ])
  },
  created () {
    this.getMethodEnabled()
    this.getPartnerCode()
    this.getAccessCode()
  },
  methods: {
    getMethodEnabled: function () {
      let filter = {
        params: {
          getWithKey: true,
          key: this.MOMO.ENABLE_PAYMENT
        }
      }
      this.GET_OBJECT_SETTING(filter).then(
        function (res) {
          let data = res.data.data
          if (data.length !== 0) {
            this.enablePayment = functionUtils.isStringTrue(data[0].value)
          }
        }.bind(this)
      )
    },
    getAccessCode: function () {
      let filter = {
        params: {
          getWithKey: true,
          key: this.MOMO.ACCESS_CODE
        }
      }
      this.GET_OBJECT_SETTING(filter).then(
        function (res) {
          let data = res.data.data
          if (data.length !== 0) {
            this.accessCode = data[0].value
          }
        }.bind(this)
      )
    },
    getPartnerCode: function () {
      let filter = {
        params: {
          getWithKey: true,
          key: this.MOMO.PARTNER_CODE
        }
      }
      this.GET_OBJECT_SETTING(filter).then(
        function (res) {
          let data = res.data.data
          if (data.length !== 0) {
            this.partnerCode = data[0].value
          }
        }.bind(this)
      )
    },
    // Check is admin
    isAdmin: function () {
      return functionUtils.isAdmin(this.GET_CURRENT_ACCOUNT)
    },
    /**
     * Has action
     */
    hasAction: function (metaCode) {
      return functionUtils.hasAction(this.GET_CURRENT_ACCOUNT, metaCode)
    },
    /**
     * create setting config
     */
    createSettingConfig: function (key, value) {
      let filter = {
        objectId: null,
        objectType: 'System',
        key: key,
        value: value
      }
      this.CREATE_OBJECT_SETTING(filter)
    },
    onCreatePaymentMomoConfig: function (event) {
      event.preventDefault()
      this.createSettingConfig(this.MOMO.ENABLE_PAYMENT, this.enablePayment)
      this.createSettingConfig(this.MOMO.PARTNER_CODE, this.partnerCode)
      this.createSettingConfig(this.MOMO.ACCESS_CODE, this.accessCode)
      this.createSettingConfig(this.MOMO.SECRET_KEY, this.secretKey)
      this.ON_SHOW_TOAST({
        'message': this.$t('common.success'),
        'styleType': ToastType.SUCCESS
      })
    },
    ...mapActions([
      'CREATE_OBJECT_SETTING',
      'GET_OBJECT_SETTING',
      'ON_SHOW_TOAST'
    ])
  }
}
</script>

<style>

</style>

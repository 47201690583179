<template>
  <v-container
    grid-list-xl
  >
    <v-form>
      <v-layout
        v-if="hasAction('view_settings') || isAdmin()"
        layout
        wrap>
        <v-flex
          xs12
          sm6
          md6
        >
          <v-textarea
            v-model="lpbPublicKey"
            :label="$t('lienVietConfig.lpbPublicKey')"
            rows="5"
          />
        </v-flex>
        <v-flex
          xs12
          sm6
          md6
        >
          <v-textarea
            v-model="merchantPublicKey"
            :label="$t('lienVietConfig.merchantPublicKey')"
            rows="5"
          />
        </v-flex>
        <v-flex
          xs12
          sm4
          md4
        >
          <v-text-field
            v-model="productName"
            :label="$t('lienVietConfig.productName')"
          />
        </v-flex>
        <v-flex
          xs12
          sm4
          md4
        >
          <v-text-field
            v-model="accessCode"
            :label="$t('lienVietConfig.accessCode')"
          />
        </v-flex>
        <v-flex
          xs12
          sm4
          md4
        >
          <v-text-field
            v-model="merchantSite"
            :label="$t('lienVietConfig.merchantSite')"
          />
        </v-flex>
        <v-flex
          xs12
          sm4
          md4>
          <span style="overflow-wrap: break-word;">
            {{ $t('lienVietConfig.WAPUrl') }}
            <v-tooltip
              top>
              <template v-slot:activator="{ on }">
                <v-icon
                  class="ml-2"
                  v-on="on"
                  @click="onCopyText(getWAPUrl())">mdi-content-copy</v-icon>
              </template>
              <span v-if="!urlCopied">{{ $t('entity.copyUrl') }}</span>
              <span
                v-else
                style="overflow-wrap: break-word;">{{ $t('entity.copied') }}: {{ urlCopied }}</span>
            </v-tooltip>
          </span>
        </v-flex>
        <v-flex
          xs12
          sm4
          md4>
          <span style="overflow-wrap: break-word;">
            {{ $t('lienVietConfig.agentAccess') }}: <span class="font-weight-bold">{{ agentAccess }} <v-tooltip
              top>
              <template v-slot:activator="{ on }">
                <v-icon
                  class="ml-2"
                  v-on="on"
                  @click="onCopyText(agentAccess)">mdi-content-copy</v-icon>
              </template>
              <span v-if="!urlCopied">{{ $t('entity.copyUrl') }}</span>
              <span
                v-else
                style="overflow-wrap: break-word;">{{ $t('entity.copied') }}: {{ urlCopied }}</span>
            </v-tooltip></span>
          </span>
        </v-flex>
        <v-flex
          xs12
          sm4
          md4>
          <span style="overflow-wrap: break-word;">
            {{ $t('lienVietConfig.agentSite') }}: <span class="font-weight-bold">{{ agentSite }} <v-tooltip
              top>
              <template v-slot:activator="{ on }">
                <v-icon
                  class="ml-2"
                  v-on="on"
                  @click="onCopyText(agentSite)">mdi-content-copy</v-icon>
              </template>
              <span v-if="!urlCopied">{{ $t('entity.copyUrl') }}</span>
              <span
                v-else
                style="overflow-wrap: break-word;">{{ $t('entity.copied') }}: {{ urlCopied }}</span>
            </v-tooltip></span>
          </span>
        </v-flex>
      </v-layout>
      <v-btn
        v-if="hasAction('update_settings') || isAdmin()"
        style="margin-left: 0;"
        color="error darken-1"
        @click="onShowInputDialog"
      >
        <span class="btn-label mr-3">
          <i class="glyphicon glyphicon-edit" />
        </span>
        <span style="text-transform: none;">{{ $t("lienVietConfig.merchantPrivateKey") }} </span>
      </v-btn>
      <v-btn
        v-if="hasAction('update_settings') || isAdmin()"
        style="margin-right: 0;"
        class="pull-right"
        color="success"
        @click="onCreateLbpConfig"
      >
        <span class="btn-label mr-3">
          <i class="glyphicon glyphicon-ok" />
        </span>
        <span style="text-transform: none;">{{ $t("common.save") }} </span>
      </v-btn>
    </v-form>
    <confirm-with-text-modal
      ref="confirmChangeMerchantPrivateKeyModal"
      :title="$t('lienVietConfig.merchantPrivateKey')"
      :input-title="$t('lienVietConfig.merchantPrivateKey')"
      :input-lines="3"
      prepend-icon="mdi-key-change"
      @onConfirm="onChangeMerchantPrivateKey"
    />
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import stringUtils from 'utils/stringUtils'
import ToastType from 'enum/toastType'
import functionUtils from 'utils/functionUtils'
import ConfirmWithTextModal from 'Components/ConfirmWithTextModal'
export default {
  components: {
    ConfirmWithTextModal
  },
  data: () => ({
    objectSettingKey: stringUtils.ObjectSettingKey,
    lpbPublicKey: null,
    merchantPublicKey: null,
    productName: null,
    urlCopied: null,
    agentSite: null,
    agentAccess: null,
    accessCode: null,
    merchantSite: null
  }),
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR',
      'GET_CURRENT_ACCOUNT'
    ])
  },
  created () {
    this.getLbpPublicKey()
    this.getMerchantPublicKey()
    this.getProductName()
    this.getMerchantInfo()
    this.getMerchantSite()
    this.getAccessCode()
  },
  methods: {
    /**
     * Get url
     */
    getWAPUrl: function () {
      return functionUtils.removeDoubleSlashesFromUrl(stringUtils.DOMAIN_BASE_PATH_ADMIN + '/wap-content')
    },
    /**
     * Copy text
     */
    onCopyText: function (text) {
      navigator.clipboard.writeText(text)
      this.urlCopied = text
    },
    /**
     * Get access code
     */
    getAccessCode: function () {
      let filter = {
        params: {
          getWithKey: true,
          key: this.objectSettingKey.LIENVIET_ACCESS_CODE
        }
      }
      this.GET_OBJECT_SETTING(filter).then(
        function (res) {
          let data = res.data.data
          if (data.length !== 0) {
            this.accessCode = data[0].value
          }
        }.bind(this)
      )
    },
    /**
     * Get merchant site
     */
    getMerchantSite: function () {
      let filter = {
        params: {
          getWithKey: true,
          key: this.objectSettingKey.LIENVIET_MERCHANT_SITE
        }
      }
      this.GET_OBJECT_SETTING(filter).then(
        function (res) {
          let data = res.data.data
          if (data.length !== 0) {
            this.merchantSite = data[0].value
          }
        }.bind(this)
      )
    },
    getMerchantInfo: function () {
      this.GET_MERCHANT_INFO().then(
        function (res) {
          let data = res.data
          this.agentSite = data.agent_site
          this.agentAccess = data.agent_access
        }.bind(this)
      )
    },
    getProductName: function () {
      let filter = {
        params: {
          getWithKey: true,
          key: this.objectSettingKey.PRODUCT_NAME
        }
      }
      this.GET_OBJECT_SETTING(filter).then(
        function (res) {
          let data = res.data.data
          if (data.length !== 0) {
            this.productName = data[0].value
          }
        }.bind(this)
      )
    },
    getMerchantPublicKey: function () {
      let filter = {
        params: {
          getWithKey: true,
          key: this.objectSettingKey.LIENVET_MERCHANT_PUB_KEY
        }
      }
      this.GET_OBJECT_SETTING(filter).then(
        function (res) {
          let data = res.data.data
          if (data.length !== 0) {
            this.merchantPublicKey = data[0].value
          }
        }.bind(this)
      )
    },
    /**
     * Get lbp public key
     */
    getLbpPublicKey: function () {
      let filter = {
        params: {
          getWithKey: true,
          key: this.objectSettingKey.LIENVIET_LPB_PUB_KEY
        }
      }
      this.GET_OBJECT_SETTING(filter).then(
        function (res) {
          let data = res.data.data
          if (data.length !== 0) {
            this.lpbPublicKey = data[0].value
          }
        }.bind(this)
      )
    },
    // Check is admin
    isAdmin: function () {
      return functionUtils.isAdmin(this.GET_CURRENT_ACCOUNT)
    },
    /**
     * Has action
     */
    hasAction: function (metaCode) {
      return functionUtils.hasAction(this.GET_CURRENT_ACCOUNT, metaCode)
    },
    /**
     * create setting config
     */
    createSettingConfig: function (key, value) {
      let filter = {
        objectId: null,
        objectType: 'System',
        key: key,
        value: value
      }
      this.CREATE_OBJECT_SETTING(filter)
    },

    /**
     * Create or update hotline config
     */
    onCreateLbpConfig: function (event) {
      event.preventDefault()
      this.createSettingConfig(this.objectSettingKey.LIENVIET_LPB_PUB_KEY, this.lpbPublicKey)
      this.createSettingConfig(this.objectSettingKey.LIENVET_MERCHANT_PUB_KEY, this.merchantPublicKey)
      this.createSettingConfig(this.objectSettingKey.PRODUCT_NAME, this.productName)
      this.createSettingConfig(this.objectSettingKey.LIENVIET_MERCHANT_SITE, this.merchantSite)
      this.createSettingConfig(this.objectSettingKey.LIENVIET_ACCESS_CODE, this.accessCode)
      this.ON_SHOW_TOAST({
        'message': this.$t('common.success'),
        'styleType': ToastType.SUCCESS
      })
    },
    onShowInputDialog: function () {
      this.$refs.confirmChangeMerchantPrivateKeyModal.onShowModal()
    },
    onChangeMerchantPrivateKey: function (text) {
      if (!functionUtils.isEmptyString(text)) {
        this.createSettingConfig(this.objectSettingKey.LIENVET_MERCHANT_PRI_KEY, text)
      }
    },
    ...mapActions([
      'CREATE_OBJECT_SETTING',
      'GET_OBJECT_SETTING',
      'ON_SHOW_TOAST',
      'GET_MERCHANT_INFO'
    ])
  }
}
</script>

<style>

</style>
